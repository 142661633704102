export const data = [
  {
    type: "rect",
    id: "A1",
    x: "421.7",
    y: "278.6",
    width: "99.2",
    height: "98",
  },
  {
    type: "rect",
    id: "B2",
    x: "749.8",
    y: "278.6",
    width: "89.2",
    height: "98",
  },
  {
    type: "rect",
    id: "B1",
    x: "842.2",
    y: "278.6",
    width: "89.2",
    height: "98",
  },
  {
    type: "polygon",
    id: "B4",
    points: "612.1,278.6 525.4,278.6 525.4,376.6 548.5,376.6 612.1,376.6",
  },
  {
    type: "polygon",
    id: "B3",
    points: "748.1,278.6 637.6,278.6 637.6,376.6 667,376.6 748.1,376.6",
  },
]
