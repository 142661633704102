import "./styles.scss"

import React from "react"

const Image = ({ image }) => {
  if (!image?.localFile?.publicURL) return null

  return (
    <section className="investment-image">
      <div className="container-fluid">
        <img src={image.localFile.publicURL} alt="" />
      </div>
    </section>
  )
}

export default Image
