import "./styles.scss"

import React from "react"

const Tourists = ({ chart, content }) => {
  const chartImage = chart?.image?.localFile?.publicURL

  if (!chartImage && !content?.title && !content?.description) return null

  return (
    <section className="investor-tourists">
      <div className="container-fluid">
        <div className="investor-tourists__wrapper">
          <div className="row flex-column-reverse flex-lg-row">
            {chartImage && (
              <div className="col-xl-5 offset-xl-1 col-lg-6">
                <img src={chartImage} alt="" />
                <div className="investor-tourists__chart-info">
                  {chart?.caption && <strong>{chart.caption}</strong>}
                  {chart?.source?.text && (
                    <small>
                      {chart.source.text}
                      {chart?.source?.link && (
                        <a
                          href={chart.source.link}
                          target="_blank"
                          rel="noreferrer nofollow"
                        >
                          {chart.source.link}
                        </a>
                      )}
                    </small>
                  )}
                </div>
              </div>
            )}
            <div className="col-xl-5 offset-xl-1 col-lg-6">
              {content?.title && <h2>{content.title}</h2>}
              {content?.description && (
                <div
                  className="investor-tourists__inner"
                  dangerouslySetInnerHTML={{ __html: content.description }}
                />
              )}
              {content?.source && <small>Źródło: {content.source}</small>}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tourists
