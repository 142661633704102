import "./styles.scss"

import React from "react"

const Numbers = ({ title, description, list }) => {
  if (!(list?.length > 0)) return null

  return (
    <section className="home-numbers">
      <div className="container-fluid">
        <div className="row">
          {list[0]?.number && list[0]?.description && (
            <div className="col-md-3 hidden-md">
              <div className="home-numbers__item">
                <span>{list[0].number}</span>
                <p>{list[0].description}</p>
              </div>
            </div>
          )}

          {(title || description) && (
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-1">
              <div className="home-numbers__baner">
                {title && <h2>{title}</h2>}
                {description && (
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                )}
              </div>
            </div>
          )}

          {list.map((item, index) => {
            const isSpacer = !item?.number || !item?.description

            return !isSpacer ? (
              <div
                key={index}
                className={`col-md-3 col-sm-4 col-6${
                  index === 0 ? " hidden-lg" : ""
                }`}
              >
                <div className="home-numbers__item">
                  <span>{item.number}</span>
                  <p>{item.description}</p>
                </div>
              </div>
            ) : (
              <div key={index} className="col-lg-3 hidden-md" />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Numbers
