export const data = [
  {
    type: "polygon",
    id: "A7",
    points:
      "277.1,304.5 295,256.2 317.2,264.4 327.2,236.7 283.6,220.7 307.2,157.1 418.7,198.4 419.2,276 356.8,275.9 337.7,269.1 337.8,326.8",
  },
  {
    type: "polygon",
    id: "A8",
    points:
      "196.5,217.7 274.6,246.5 263.3,277 282,284.4 293.5,253.1 316.3,261 324.3,238.5 280.6,221.7 304.5,156.2 229.1,128.6",
  },
  {
    type: "polygon",
    id: "A9",
    points: "169.7,291.8 195.5,219.7 271.5,247.7 246,318.9",
  },
  {
    type: "polygon",
    id: "A10",
    points:
      "125.3,334.1 152.5,334.1 167.8,293.1 304.7,343.5 304.7,376.6 116.9,376.6",
  },
  {
    type: "polygon",
    id: "A11",
    points:
      "116.9,379.5 253.8,379.5 253.8,401.1 214.3,401.1 214.3,505.6 118.8,507.6 96,481.7",
  },
  {
    type: "polygon",
    id: "A12",
    points:
      "216.5,403.6 304.7,403.6 304.7,488.9 266.6,488.9 266.8,490.7 256.6,490.7 256.6,504.9 216.5,504.9",
  },
  {
    type: "polygon",
    id: "A13",
    points:
      "421.7,398.1 421.7,403.6 384.8,403.6 384.8,379.5 340.1,379.5 340.1,403.6 307.5,403.6 307.5,501.6 429.5,501.6 429.5,407.6 429.5,403.6 429.5,398.1",
  },
  {
    type: "polygon",
    id: "A14",
    points:
      "520.9,501.6 520.9,400.6 520.9,379.5 421.7,379.5 421.7,396.6 432.5,396.9 432.5,501.6",
  },
  {
    type: "rect",
    id: "A15",
    x: "421.7",
    y: "278.6",
    width: "99.2",
    height: "98",
  },
  {
    type: "rect",
    id: "B14",
    x: "842.2",
    y: "278.6",
    width: "89.2",
    height: "98",
  },
  {
    type: "rect",
    id: "B15",
    x: "749.8",
    y: "278.6",
    width: "89.2",
    height: "98",
  },
  {
    type: "polygon",
    id: "B16",
    points:
      "724.6,278.6 687.6,278.6 665.1,278.6 665.1,335.1 687.6,335.1 687.6,376.6 747.1,376.6 747.1,278.6",
  },
  {
    type: "polygon",
    id: "B17",
    points:
      "662.4,337.1 662.4,278.6 613.6,278.6 613.6,376.6 662.4,376.6 685.5,376.6 685.5,337.1",
  },
  {
    type: "polygon",
    id: "B18",
    points: "611.2,278.6 525.4,278.6 525.4,376.6 548.5,376.6 611.2,376.6",
  },
  {
    type: "polygon",
    id: "B19",
    points:
      "582.5,403.6 582.5,379.5 525.4,379.5 525.4,403.6 525.4,501.6 611.4,501.6 611.4,403.6",
  },
  {
    type: "polygon",
    id: "B20",
    points:
      "685.5,441.6 685.5,403.6 613.8,403.6 613.8,501.6 663.7,501.6 663.7,441.6",
  },
  {
    type: "polygon",
    id: "B21",
    points:
      "748.1,403.6 686.5,403.6 686.5,441.6 664.7,441.6 664.7,501.6 748.1,501.6",
  },
  {
    type: "rect",
    id: "B22",
    x: "750",
    y: "403.6",
    width: "89.2",
    height: "98",
  },
  {
    type: "polygon",
    id: "B23",
    points:
      "931.4,440.2 931.4,403.6 842.2,403.6 842.2,501.6 971.3,501.6 973.3,440.2",
  },
]
