import "./styles.scss"

import React from "react"

const Hero = ({ image, content }) => {
  const imageUrl = image?.localFile?.publicURL

  if (!content?.title && !content?.description) return null

  return (
    <section
      className={`about-hero${!imageUrl ? " about-hero--imageless" : ""}`}
    >
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-xl-5 col-lg-6">
            {imageUrl && (
              <div className="about-hero__image">
                <img src={imageUrl} alt="" />
              </div>
            )}
          </div>
          <div className="col-xl-6 offset-xl-1 col-lg-6">
            <div className="about-hero__wrapper">
              <div>
                {content?.title && (
                  <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
                )}
                {content?.description && (
                  <div
                    className="about-hero__inner"
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                )}
              </div>
              {(content?.info?.logo?.localFile?.publicURL ||
                content?.info?.link) && (
                <div className="about-hero__info">
                  {content?.info?.logo?.localFile?.publicURL && (
                    <img src={content.info.logo.localFile.publicURL} alt="" />
                  )}
                  {content?.info?.link && (
                    <small>
                      <a
                        href={`https://${content.info.link}/`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {content.info.link}
                      </a>
                    </small>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
