import "./styles.scss"

import React from "react"

const Main = ({ content }) => {
  if (!content) return null

  return (
    <section className="policy-privacy-main">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            className="col-md-10"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </section>
  )
}

export default Main
