import "./styles.scss"

import React from "react"

const Desc = ({ description, icons }) => {
  const iconsFiltered =
    icons?.length > 0
      ? icons.filter(item => item?.icon?.localFile?.publicURL && item?.label)
      : undefined

  if (!description && !(iconsFiltered?.length > 0)) return null

  return (
    <section className="investment-desc">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-7 col-xxl-6">
            {description && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}

            {iconsFiltered?.length > 0 && (
              <div className="investment-desc__row">
                {iconsFiltered.map((item, index) => (
                  <div key={index} className="investment-desc__item">
                    <div className="investment-desc__item-image">
                      <img src={item.icon.localFile.publicURL} alt="" />
                    </div>
                    <h4>{item.label}</h4>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="col-lg-4 offset-lg-1 offset-xxl-2">
            <div className="investment-desc__logo">
              <img
                src={require("assets/images/logo-vertical.svg").default}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Desc
