import "./styles.scss"

import React from "react"
import PageHeader from "components/PageHeader"

const DistrictAdvantages = ({ content, image }) => {
  const imageUrl = image?.localFile?.publicURL

  if (!imageUrl && !content?.title && !content?.description) return null

  return (
    <section className="investor-district-advantages">
      <div className="container-fluid">
        <PageHeader title="Zalety dzielnicy" type="sub" color="green" />

        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-lg-6">
            {content?.title && <h2>{content.title}</h2>}
            {content?.description && (
              <div
                className="investor-district-advantages__inner"
                dangerouslySetInnerHTML={{ __html: content.description }}
              />
            )}
          </div>
          {imageUrl && (
            <div className="col-xl-5 offset-xl-1 col-lg-6">
              <img src={imageUrl} alt="" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default DistrictAdvantages
