import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import {
  About,
  Content,
  Grid,
  Numbers,
  Convenience,
  Gallery,
} from "page_components/home"

import { parseGqlData } from "utils/parseGqlData"

const Home = ({ data }) => {
  const pageData = parseGqlData({
    data: data?.allWpPage?.nodes,
    queryKey: "Home",
    single: true,
  })

  return (
    <Layout
      seo={{
        title: pageData?.seo?.title ?? pageData.title,
        description: pageData?.seo?.metaDesc,
      }}
      homeHero={{ ...pageData?.hero }}
      isHome
    >
      <About {...pageData?.about} />
      <Content {...pageData?.standard} />
      <Grid icons={pageData?.icons?.list} {...pageData?.section} />
      <Numbers {...pageData?.numbers} />
      <Convenience {...pageData?.convenients} />
      <Gallery {...pageData?.gallery} />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDoxMTIw" } }) {
      nodes {
        title
        acfHome {
          homeHeroTitle
          homeHeroBackground {
            desktop {
              localFile {
                publicURL
              }
            }
            tablet {
              localFile {
                publicURL
              }
            }
            mobile {
              localFile {
                publicURL
              }
            }
          }
          homeAboutContent {
            title
            description
          }
          homeAboutImage {
            localFile {
              publicURL
            }
          }
          homeStandardContent {
            title
            description
          }
          homeStandardImages {
            localFile {
              publicURL
            }
          }
          homeIconsList {
            icon {
              localFile {
                publicURL
              }
            }
            label
          }
          homeSectionOne {
            content {
              title
              description
            }
            image {
              localFile {
                publicURL
              }
            }
          }
          homeSectionTwo {
            content {
              title
              description
            }
            image {
              localFile {
                publicURL
              }
            }
          }
          homeSectionThree {
            content {
              title
              description
            }
            images {
              localFile {
                publicURL
              }
            }
          }
          homeSectionFour {
            content {
              title
              description
            }
            image {
              localFile {
                publicURL
              }
            }
          }
          homeSectionFive {
            content {
              title
              description
            }
            image {
              localFile {
                publicURL
              }
            }
          }
          homeNumbersTitle
          homeNumbersDescription
          homeNumbersList {
            number
            description
          }
          homeConvenientsList {
            icon {
              localFile {
                publicURL
              }
            }
            content {
              title
              description
            }
          }
          homeGalleryTitle
          homeGalleryImages {
            image {
              localFile {
                publicURL
              }
            }
            columnSize
            imageHeight
          }
        }
        seo {
          title
          metaDesc
        }
      }
    }
  }
`

export default Home
