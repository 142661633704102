import "./styles.scss"

import React from "react"

const Families = ({ image, content }) => {
  const imageUrl = image?.localFile?.publicURL

  if (!imageUrl && !content?.title && !content?.description) return null

  return (
    <section className="investor-families">
      <div className="container-fluid">
        <div className="investor-families__wrapper">
          <div className="row align-items-center flex-column-reverse flex-lg-row">
            {imageUrl && (
              <div className="col-xl-5 col-lg-5">
                <img src={imageUrl} alt="" />
              </div>
            )}
            {(content?.title || content?.description) && (
              <div className="col-xl-5 offset-xl-1 col-lg-7">
                {content?.title && (
                  <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
                )}
                {content?.description && (
                  <div
                    className="investor-families__inner"
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Families
