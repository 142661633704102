import "./styles.scss"

import React from "react"

const Convenience = ({ title, list }) => {
  if (!(list?.length > 0)) return null

  return (
    <section className="investment-convenience">
      <div className="container-fluid">
        <div className="investment-convenience__wrapper">
          {title && <h2>{title}</h2>}
          <div className="row">
            {list.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-sm-6">
                <div className="investment-convenience__item">
                  <div className="investment-convenience__item-image">
                    {item?.icon?.localFile?.publicURL && (
                      <img src={item.icon.localFile.publicURL} alt="" />
                    )}
                  </div>
                  {item?.description && <p>{item.description}</p>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Convenience
