import React from "react"

import { Apartment, Building } from "./components"

const getBodyComponent = props => {
  if (!props?.type) return null

  switch (props.type) {
    case "apartment":
      return <Apartment {...props} />
    case "building":
      return <Building {...props} />
    default:
      return null
  }
}

const getAreaString = number => {
  if (number === 1) return `${number} pokój`

  const array = number.toString().split("")
  const lastNumber = parseInt(array[array.length - 1])

  if (lastNumber === 2 || lastNumber === 3 || lastNumber === 4) {
    return `${number} pokoje`
  } else {
    return `${number} pokoi`
  }
}

export { getBodyComponent, getAreaString }
