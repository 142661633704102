export const parseGqlData = (
  settings = {
    data: null,
    queryKey: null,
    single: false,
  }
) => {
  const { data, queryKey, single } = settings;

  if (!data) return undefined;

  if (queryKey) {
    const dataDeepCopy = JSON.parse(
      JSON.stringify(Array.isArray(data) ? data : [data])
    );

    const dataParsed = dataDeepCopy.map((object) => {
      const acfKey = `acf${queryKey}`;
      const acfData = object[acfKey];

      const dataFormatted = {};

      if (acfData) {
        Object.entries(acfData).forEach(([key, value]) => {
          const keyFormatted = firstLetterLowercase(
            key.replace(queryKey.toLowerCase(), "")
          );

          const firstUppercase = findFirstUppercase(keyFormatted);

          if (firstUppercase) {
            formatData(
              dataFormatted,
              keyFormatted,
              value,
              keyFormatted.indexOf(firstUppercase)
            );
          } else {
            const firstUppercase = findFirstUppercase(key);

            formatData(dataFormatted, key, value, key.indexOf(firstUppercase));
          }
        });

        delete object[acfKey];

        return { ...object, ...dataFormatted };
      } else {
        return object;
      }
    });

    return single ? dataParsed[0] : dataParsed;
  } else {
    return single ? data[0] : data;
  }
};

const findFirstUppercase = (string) => {
  if (!string) return null;

  const match = string.match(/[A-Z]/);
  return match ? match[0] : null;
};

const firstLetterLowercase = (string) =>
  string
    ? string.charAt(0).toLowerCase() + string.substring(1, string.length)
    : null;

const formatData = (object, key, value, index) => {
  const groupKey = key.slice(0, index);
  const propKey = firstLetterLowercase(key.slice(index, key.length));

  object[groupKey] = {
    ...(object[groupKey] ? object[groupKey] : {}),
    [propKey]: value,
  };
};
