import "./styles.scss"

import React, { useEffect } from "react"
import { motion } from "framer-motion"

const Hero = ({
  title,
  background,
  isAnimated,
  isBrowser,
  setIsAnimated,
  setIsPopupOpen,
}) => {
  const desktopBg = background?.desktop?.localFile?.publicURL
  const tabletBg = background?.tablet?.localFile?.publicURL
  const mobileBg = background?.mobile?.localFile?.publicURL

  const heroAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: {
      type: "tween",
      duration: 0.5,
      delay: 0.5,
    },
  }

  const wrapperAnimation = {
    initial: { y: "100%" },
    animate: { y: 0 },
    transition: { type: "tween", duration: 0.5, delay: 1 },
    onAnimationComplete: definition => {
      if (definition.y === 0) {
        if (isBrowser) {
          window.sessionStorage.setItem("animated-entry", "init")
        }

        if (setIsAnimated && typeof setIsAnimated === "function") {
          setIsAnimated(false)
        }
      }
    },
  }

  useEffect(() => {
    if (!isAnimated && isBrowser) {
      if (!window.sessionStorage.getItem("popup-visited")) {
        setTimeout(() => {
          setIsPopupOpen(true)
        }, 1000)
      }
    }
  }, [isAnimated, isBrowser, setIsPopupOpen])

  return (
    <motion.section
      className="home-hero"
      {...(isAnimated ? heroAnimation : {})}
    >
      <div className="container-fluid">
        <motion.div
          className="home-hero__wrapper"
          {...(isAnimated ? wrapperAnimation : {})}
        >
          {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
        </motion.div>
      </div>
      {desktopBg && (
        <>
          <div
            className="home-hero__background home-hero__background--desktop"
            style={{ backgroundImage: `url(${desktopBg})` }}
          />
          <div
            className="home-hero__background home-hero__background--tablet"
            style={{ backgroundImage: `url(${tabletBg ?? desktopBg})` }}
          />
          <div
            className="home-hero__background home-hero__background--mobile"
            style={{ backgroundImage: `url(${mobileBg ?? desktopBg})` }}
          />
        </>
      )}
    </motion.section>
  )
}

export default Hero
