import "./styles.scss"

import React from "react"

const Gym = ({ content, image }) => {
  const imageUrl = image?.localFile?.publicURL

  if (!imageUrl && !content?.description) return null

  return (
    <section
      className={`location-gym${!imageUrl ? " location-gym--imageless" : ""}`}
    >
      <div className="container-fluid">
        <div className="row align-items-end">
          <div className="col-xl-5">
            {content?.title && <h2>{content.title}</h2>}
            {content?.description && (
              <div dangerouslySetInnerHTML={{ __html: content.description }} />
            )}
          </div>
          {imageUrl && (
            <div className="col-xl-6 offset-xl-1">
              <img src={imageUrl} alt="" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Gym
