import "./styles.scss"

import React from "react"

import IconBox from "components/IconBox"

const Garden = ({ content, background }) => {
  return (
    <section
      className="convenients-garden"
      style={
        background?.image?.localFile?.publicURL
          ? {
              backgroundImage: `url(${background.image.localFile.publicURL})`,
            }
          : undefined
      }
    >
      <div
        className="convenients-garden__thumbnail"
        style={
          background?.image?.localFile?.publicURL
            ? {
                backgroundImage: `url(${background.image.localFile.publicURL})`,
              }
            : undefined
        }
      >
        {(background?.title?.color || background?.title?.white) && (
          <p>
            {background?.title?.color && <span>500m²</span>}{" "}
            {background?.title?.white}
          </p>
        )}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-5 col-lg-6">
            {(content?.title || content?.description) && (
              <div className="convenients-garden__content">
                {content?.icon?.localFile?.publicURL && (
                  <IconBox icon={content.icon.localFile.publicURL} />
                )}
                {content?.title && <h2>{content.title}</h2>}
                {content?.description && (
                  <div
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                )}
              </div>
            )}
          </div>
          {(background?.title?.color || background?.title?.white) && (
            <div className="col-xxl-7 col-lg-6">
              <div className="convenients-garden__area">
                <p>
                  {background?.title?.color && (
                    <span>{background.title.color}</span>
                  )}
                  {background?.title?.color && background?.title?.white && (
                    <br />
                  )}
                  {background?.title?.white}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Garden
