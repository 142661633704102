import "./styles.scss"

import React from "react"

const Numbers = ({ list }) => {
  if (!(list?.length > 0)) return null

  return (
    <section className="about-numbers">
      <div className="container-fluid">
        <div className="row">
          {list.map((item, index) => (
            <div key={index} className="col-lg-3 col-md-6">
              <div className="about-numbers__item">
                <span>{item?.number}</span>
                <h4>{item?.description}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Numbers
