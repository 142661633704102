import "./styles.scss"

import React, { Fragment } from "react"

const BottomGrid = ({ content }) => {
  if (!(content?.length > 0)) return null

  return (
    <section className="investor-bottom-grid">
      <div className="container-fluid">
        {content.map((item, index) => {
          const isOdd = index % 2 === 0

          const image = item?.image?.localFile?.publicURL
          const title = item?.content?.title
          const description = item?.content?.description

          return (
            <Fragment key={index}>
              {isOdd ? (
                <div className="row align-items-center">
                  <div className="col-xl-5 offset-xl-1 col-lg-6">
                    {title && (
                      <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    )}
                    {description && (
                      <div
                        className="investor-bottom-grid__inner"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )}
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-6">
                    {image && <img src={image} alt="" />}
                  </div>
                </div>
              ) : (
                <div className="row align-items-center flex-column-reverse flex-lg-row">
                  <div className="col-xl-5 col-lg-6">
                    {image && <img src={image} alt="" />}
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-6">
                    {title && (
                      <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    )}
                    {description && (
                      <div
                        className="investor-bottom-grid__inner"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )}
                  </div>
                </div>
              )}

              {index + 1 !== content.length && (
                <div className="investor-bottom-grid__divider" />
              )}
            </Fragment>
          )
        })}
      </div>
    </section>
  )
}

export default BottomGrid
