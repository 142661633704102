import React from "react"

import { parseStatus } from "utils/apartments"

const getFlyObject = (apartments, name) => {
  if (!(apartments?.length > 0) || !name) return null

  const apartment = apartments.find(
    apartment => apartment.acfApartment.apartmentNumber === name
  )

  return apartment
    ? {
        type: "apartment",
        name: apartment.acfApartment.apartmentNumber,
        area: parseFloat(apartment.acfApartment.area),
        rooms: apartment.acfApartment.roomsnumber,
        floor: apartment.acfApartment.floor ?? "Parter",
        status: parseStatus(apartment.acfApartment.status),
        url: apartment.uri,
      }
    : null
}

const renderSvg = (data, callback) => {
  if (!(data?.length > 0)) return null

  return (
    <svg viewBox="0 0 1090 602">
      {data.map((item, index) => {
        const Type = item.type

        return (
          <Type
            key={index}
            {...item}
            {...(typeof callback === "function"
              ? callback(item.id)
              : undefined)}
          />
        )
      })}
    </svg>
  )
}

export { getFlyObject, renderSvg }
