import "./styles.scss"

import React from "react"
import Slider from "react-slick"

const Content = ({ content, images }) => {
  const { title, description } = content ?? {}

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  if (!(images?.length > 0) && !title && !description) return null

  return (
    <section className="home-content">
      <div className="container-fluid">
        <div className="row flex-lg-row-reverse">
          <div className="col-xl-7 col-lg-6">
            <Slider {...settings}>
              {images.map((item, index) => (
                <div key={index}>
                  <div
                    className="home-content__image"
                    style={{
                      backgroundImage: `url(${item?.localFile?.publicURL})`,
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
          {(title || description) && (
            <div className="col-xl-5 col-lg-6">
              <div className="home-content__body">
                <div className="home-content__body-inner">
                  {title && <h2>{title}</h2>}
                  {description && (
                    <div
                      className="home-content__description"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
