import "react-image-lightbox/style.css"
import "./styles.scss"

import React, { useState } from "react"
import Lightbox from "react-image-lightbox"

import PageHeader from "components/PageHeader"

const Gallery = ({ title, images }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const imagesFiltered =
    images?.length > 0
      ? images.filter(item => item?.image?.localFile?.publicURL)
      : undefined

  if (!(imagesFiltered?.length > 0)) return null

  const imagesLightbox = imagesFiltered.map(
    item => item.image.localFile.publicURL
  )

  return (
    <section className="home-gallery">
      <div className="container-fluid">
        {title && <PageHeader title={title} type="sub" color="gray" />}

        <div className="row">
          {imagesFiltered.map((item, index) => (
            <div key={index} className={`col-md-${item.columnSize}`}>
              <button
                className={`home-gallery__item${
                  item.imageHeight === "low" ? " home-gallery__item--lower" : ""
                }`}
                style={{
                  backgroundImage: `url('${item.image.localFile.publicURL}')`,
                }}
                onClick={() => {
                  setPhotoIndex(index)
                  setIsLightboxOpen(true)
                }}
              >
                Zdjęcie {index + 1}
              </button>
            </div>
          ))}
        </div>
      </div>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={imagesLightbox[photoIndex]}
          nextSrc={imagesLightbox[(photoIndex + 1) % imagesLightbox.length]}
          prevSrc={
            imagesLightbox[
              (photoIndex + imagesLightbox.length - 1) % imagesLightbox.length
            ]
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imagesLightbox.length - 1) % imagesLightbox.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imagesLightbox.length)
          }
        />
      )}
    </section>
  )
}

export default Gallery
