import "./styles.scss"

import React from "react"

const Grid = ({ content, image }) => {
  if (!content?.title && !content?.description) return null

  return (
    <section className="investor-grid">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <div className="investor-grid__wrapper">
              <div>
                {content?.title && <h2>{content.title}</h2>}
                {content?.description && (
                  <div
                    className="investor-grid__inner"
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                )}
              </div>
              {content?.source && <small>{content.source}</small>}
            </div>
          </div>
          {image?.localFile?.publicURL && (
            <div className="col-xl-5 offset-xl-1 col-md-6">
              <img src={image.localFile.publicURL} alt="" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Grid
