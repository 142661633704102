import "./styles.scss"

import React, { Fragment } from "react"

const PointsList = ({ list, image }) => {
  const listLeft = list?.left?.length > 0 ? list.left : undefined
  const listRight = list?.right?.length > 0 ? list.right : undefined
  const imageUrl = image?.localFile?.publicURL

  if (!listLeft && !listRight) return null

  return (
    <section className="location-points-list">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5">
            {listLeft &&
              listLeft.map((item, index) => (
                <Fragment key={index}>
                  {item?.label && <strong>{item.label}</strong>}
                  {item?.value && <p>{item.value}</p>}
                </Fragment>
              ))}
          </div>
          <div className="col-lg-6 offset-lg-1">
            {imageUrl && (
              <div className="location-points-list__image">
                <img src={imageUrl} alt="" />
              </div>
            )}
            {listRight &&
              listRight.map((item, index) => (
                <Fragment key={index}>
                  {item?.label && <strong>{item.label}</strong>}
                  {item?.value && <p>{item.value}</p>}
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PointsList
