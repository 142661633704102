import "./styles.scss"

import React from "react"

const Pricing = ({ chart, content }) => {
  const chartImage = chart?.image?.localFile?.publicURL

  if (!chartImage && !content?.title && !content?.description) return null

  return (
    <section className="investor-pricing">
      <div className="container-fluid">
        <div className="row flex-column-reverse flex-lg-row">
          {chartImage && (
            <div className="col-lg-6">
              <img src={chartImage} alt="" />
              {chart?.data?.length > 0 && (
                <div className="investor-pricing__chart-data">
                  {chart.data.map((item, index) => (
                    <strong key={index}>
                      {item?.label} <span>{item?.value}</span>
                    </strong>
                  ))}
                </div>
              )}
              {chart?.source && (
                <small className="d-block">źródło: {chart.source}</small>
              )}
            </div>
          )}

          {(content?.title || content?.description) && (
            <div className="col-xl-5 offset-xl-1 col-lg-6">
              {content?.title && <h2>{content.title}</h2>}
              {content?.description && (
                <div className="investor-pricing__inner">
                  <div
                    dangerouslySetInnerHTML={{ __html: content.description }}
                  />
                  {content?.vs?.left?.value && content?.vs?.right?.value && (
                    <div className="investor-pricing__row">
                      <div className="investor-pricing__item">
                        <h3>
                          {content.vs.left.value}{" "}
                          {content.vs.left?.postscript && (
                            <span>{content.vs.left.postscript}</span>
                          )}
                        </h3>
                        {content.vs.left?.year && <p>{content.vs.left.year}</p>}
                      </div>
                      <h5>VS</h5>
                      <div className="investor-pricing__item">
                        <h3>
                          {content.vs.right.value}{" "}
                          {content.vs.right?.postscript && (
                            <span>{content.vs.right.postscript}</span>
                          )}
                        </h3>
                        {content.vs.right?.year && (
                          <p>{content.vs.right.year}</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {content?.source && <small>Źródło: {content.source}</small>}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Pricing
