import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Apartments = ({ content, image }) => {
  if (!content?.description && !(content?.columns?.length > 0)) return null

  return (
    <section className="investment-apartments">
      <div className="container-fluid">
        {content?.title && <h2>{content.title}</h2>}
        <div className="row">
          <div className="col-xl-4 col-lg-5">
            {content?.description && (
              <div dangerouslySetInnerHTML={{ __html: content.description }} />
            )}
            {content?.columns?.length > 0 ? (
              <div className="investment-apartments__inner">
                <div className="row">
                  {content.columns.map((item, index) => (
                    <div key={index} className={`col-${item.size}`}>
                      {item?.label && <strong>{item.label}</strong>}
                      {item?.description && <span>{item.description}</span>}
                    </div>
                  ))}
                </div>
                <Button to="/mieszkania/">zobacz</Button>
              </div>
            ) : (
              <Button to="/mieszkania/">zobacz</Button>
            )}
          </div>
          {image?.localFile?.publicURL && (
            <div className="col-xl-7 offset-xl-1 col-lg-7">
              <img src={image.localFile.publicURL} alt="" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Apartments
