import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import { Hero, Content, Garden } from "page_components/convenients"

import { parseGqlData } from "utils/parseGqlData"

const Convenients = ({ data }) => {
  const pageData = parseGqlData({
    data: data?.allWpPage?.nodes,
    queryKey: "Convenients",
    single: true,
  })

  return (
    <Layout
      seo={{
        title: pageData?.seo?.title ?? pageData.title,
        description: pageData?.seo?.metaDesc,
      }}
    >
      <Hero title={pageData.title} {...pageData?.hero} />
      {pageData?.sections?.content?.map((item, index) => (
        <Content
          key={index}
          images={item.images}
          icon={item.content.icon?.localFile?.publicURL}
          title={item.content.title}
          description={item.content.description}
          hasBackground={item.settings.hasBackground}
          isReversed={item.settings.isReversed}
          isSpaced={item.settings.isSpaced}
          isFull={item.settings.isFull}
        />
      ))}
      <Garden {...pageData?.garden} />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzUy" } }) {
      nodes {
        title
        acfConvenients {
          convenientsHeroBackground {
            localFile {
              publicURL
            }
          }
          convenientsHeroContent {
            title
            description
          }
          convenientsSectionsContent {
            content {
              icon {
                localFile {
                  publicURL
                }
              }
              title
              description
            }
            images {
              localFile {
                publicURL
              }
            }
            settings {
              hasBackground
              isFull
              isReversed
              isSpaced
            }
          }
          convenientsGardenContent {
            icon {
              localFile {
                publicURL
              }
            }
            title
            description
          }
          convenientsGardenBackground {
            image {
              localFile {
                publicURL
              }
            }
            title {
              color
              white
            }
          }
        }
        seo {
          title
          metaDesc
        }
      }
    }
  }
`

export default Convenients
