const getRawStatus = status => {
  switch (status) {
    case "wolne":
      return "available"
    case "rezerwacja":
      return "reserved"
    case "sprzedane":
      return "sold"
    default:
      return undefined
  }
}

const parseStatus = status => {
  switch (status) {
    case "available":
      return "wolne"
    case "reserved":
      return "rezerwacja"
    case "sold":
      return "sprzedane"
    default:
      return undefined
  }
}

export { getRawStatus, parseStatus }
