import "./styles.scss"

import React from "react"

const BottomGrid = ({ one, two, three }) => {
  return (
    <section className="location-bottom-grid">
      <div className="container-fluid">
        {(one?.content?.title || one?.content?.description) && (
          <>
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="location-bottom-grid__image">
                  {one?.image?.localFile?.publicURL && (
                    <img src={one.image.localFile.publicURL} alt="" />
                  )}
                </div>
              </div>
              <div className="col-xl-5 offset-xl-1 col-lg-6">
                {one?.content?.title && <h2>{one.content.title}</h2>}
                {one?.content?.description && (
                  <div
                    className="location-bottom-grid__inner"
                    dangerouslySetInnerHTML={{
                      __html: one.content.description,
                    }}
                  />
                )}
              </div>
            </div>

            {(two?.content?.title || two?.content?.description) && (
              <div className="location-bottom-grid__divider--min-85" />
            )}
          </>
        )}

        {(two?.content?.title || two?.content?.description) && (
          <>
            {two?.content?.title && <h2>{two.content.title}</h2>}
            <div className="row flex-column-reverse flex-lg-row">
              <div className="col-xl-5 col-lg-6">
                {two?.content?.description && (
                  <div
                    className="location-bottom-grid__inner"
                    dangerouslySetInnerHTML={{
                      __html: two.content.description,
                    }}
                  />
                )}
              </div>
              <div className="col-xl-6 offset-xl-1 col-lg-6">
                <div className="location-bottom-grid__image location-bottom-grid__image--1">
                  {two?.image?.localFile?.publicURL && (
                    <img src={two.image.localFile.publicURL} alt="" />
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {(three?.content?.title || three?.content?.description) && (
          <>
            <div className="location-bottom-grid__divider--150" />

            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="location-bottom-grid__image">
                  {three?.image?.localFile?.publicURL && (
                    <img src={three.image.localFile.publicURL} alt="" />
                  )}
                </div>
              </div>
              <div className="col-xl-5 offset-xl-1 col-lg-6">
                {three?.content?.title && <h2>{three.content.title}</h2>}
                {three?.content?.description && (
                  <div
                    className="location-bottom-grid__inner"
                    dangerouslySetInnerHTML={{
                      __html: three.content.description,
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default BottomGrid
