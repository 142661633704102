import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

const Hero = ({ title, background, content }) => {
  return (
    <section
      className="convenients-hero"
      style={
        background?.localFile?.publicURL
          ? { backgroundImage: `url(${background.localFile.publicURL})` }
          : undefined
      }
    >
      <div className="container-fluid">
        <motion.h1
          initial={{ y: 24, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "tween", duration: 0.5, delay: 0.1 }}
        >
          {title}
        </motion.h1>
        {(content?.title || content?.description) && (
          <motion.div
            className="convenients-hero__wrapper"
            initial={{ y: -24, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "tween", duration: 0.5, delay: 0.1 }}
          >
            {content?.title && (
              <h2>
                <span>
                  {content.title.split(" ").map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </span>
              </h2>
            )}
            {content?.description && (
              <div dangerouslySetInnerHTML={{ __html: content.description }} />
            )}
          </motion.div>
        )}
      </div>
    </section>
  )
}

export default Hero
