import "./styles.scss"

import React from "react"

const TopInfo = ({ content, block }) => {
  if (!content?.title && !content?.description) return null

  return (
    <section className="investor-top-info">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5 offset-xl-1 col-lg-7">
            {content?.title && <h2>{content.title}</h2>}
            {content?.description && (
              <div
                className="investor-top-info__inner"
                dangerouslySetInnerHTML={{ __html: content.description }}
              />
            )}
            {content?.icons?.length > 0 && (
              <div className="investor-top-info__row">
                {content.icons.map((item, index) => (
                  <div key={index} className="investor-top-info__item">
                    {item?.icon?.localFile?.publicURL && (
                      <div className="investor-top-info__item-image">
                        <img src={item.icon.localFile.publicURL} alt="" />
                      </div>
                    )}
                    {item?.label && <p>{item.label}</p>}
                  </div>
                ))}
              </div>
            )}
          </div>

          {(block?.number || block?.label) && (
            <div className="col-xl-4 offset-xl-2 col-lg-5">
              <div className="investor-top-info__info">
                {block?.number && (
                  <h5>
                    {block.number}{" "}
                    {block?.postscript && <span>{block.postscript}</span>}
                  </h5>
                )}
                {block?.label && <h4>{block.label}</h4>}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default TopInfo
