import "./styles.scss"

import React from "react"

const TopGrid = ({ intro, one, two, three, four }) => {
  return (
    <section className="location-top-grid">
      <div className="container-fluid">
        {(intro?.list?.length > 0 || intro?.icons?.length > 0) && (
          <>
            {intro?.title && <h2>{intro.title}</h2>}

            <div className="row align-items-center">
              <div className="col-xl-6">
                {intro?.list?.length > 0 && (
                  <ul>
                    {intro.list.map((item, index) => (
                      <li key={index}>{item.value}</li>
                    ))}
                  </ul>
                )}
              </div>
              {intro?.icons?.length > 0 &&
                intro.icons.map((item, index) => (
                  <div key={index} className="col-xl-2 col-sm-4">
                    <div className="location-top-grid__item">
                      <div className="location-top-grid__item-image">
                        {item?.icon?.localFile?.publicURL && (
                          <img src={item.icon.localFile.publicURL} alt="" />
                        )}
                      </div>
                      {item?.label && <h4>{item.label}</h4>}
                    </div>
                  </div>
                ))}
            </div>

            <div className="location-top-grid__divider--100" />
          </>
        )}

        {(one?.image?.localFile?.publicURL ||
          one?.content?.title ||
          one?.content?.description) && (
          <>
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="location-top-grid__image location-top-grid__image--1">
                  {one?.image?.localFile?.publicURL && (
                    <img src={one.image.localFile.publicURL} alt="" />
                  )}
                </div>
              </div>
              <div className="col-xl-5 offset-xl-1 col-lg-6">
                {one?.content?.title && <h2>{one.content.title}</h2>}
                {one?.content?.description && (
                  <div
                    className="location-top-grid__inner"
                    dangerouslySetInnerHTML={{
                      __html: one.content.description,
                    }}
                  />
                )}
              </div>
            </div>

            <div className="location-top-grid__divider--100" />
          </>
        )}

        {(two?.left?.description || two?.right?.description) && (
          <>
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                {two?.left?.title && <h3>{two.left.title}</h3>}
                {two?.left?.description && (
                  <div
                    className="location-top-grid__inner"
                    dangerouslySetInnerHTML={{ __html: two.left.description }}
                  />
                )}
              </div>
              <div className="col-xl-5 offset-xl-1 col-lg-6">
                {two?.right?.title && <h3>{two.right.title}</h3>}
                {two?.right?.description && (
                  <div
                    className="location-top-grid__inner"
                    dangerouslySetInnerHTML={{ __html: two.right.description }}
                  />
                )}
              </div>
            </div>

            <div className="location-top-grid__divider--200" />
          </>
        )}

        {(three?.image?.localFile?.publicURL ||
          three?.content?.title ||
          three?.content?.description) && (
          <>
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="location-top-grid__image location-top-grid__image--1">
                  {three?.image?.localFile?.publicURL && (
                    <img src={three.image.localFile.publicURL} alt="" />
                  )}
                </div>
              </div>
              <div className="col-xl-5 offset-xl-1 col-lg-6">
                {three?.content?.title && <h2>{three.content.title}</h2>}
                {three?.content?.description && (
                  <div
                    className="location-top-grid__inner"
                    dangerouslySetInnerHTML={{
                      __html: three.content.description,
                    }}
                  />
                )}
              </div>
            </div>

            <div className="location-top-grid__divider--150" />

            {!four?.title && !four?.description && (
              <div className="location-top-grid__divider--100" />
            )}
          </>
        )}

        {(four?.title || four?.description) && (
          <div className="row">
            <div className="col-lg-5">
              {four?.title && <h2>{four.title}</h2>}
              {four?.description && (
                <div
                  className="location-top-grid__inner"
                  dangerouslySetInnerHTML={{ __html: four.description }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default TopGrid
