import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import {
  DescWithMap,
  TopGrid,
  PointsList,
  BottomGrid,
  Gym,
} from "page_components/location"

import { parseGqlData } from "utils/parseGqlData"

const Location = ({ data }) => {
  const pageData = parseGqlData({
    data: data?.allWpPage?.nodes,
    queryKey: "Location",
    single: true,
  })

  return (
    <Layout
      seo={{
        title: pageData?.seo?.title ?? pageData.title,
        description: pageData?.seo?.metaDesc,
      }}
    >
      <PageHeader title={pageData.title} />
      <DescWithMap {...pageData?.hero} />
      <TopGrid {...pageData?.topgrid} />
      <PointsList {...pageData?.points} />
      <BottomGrid {...pageData?.bottomgrid} />
      <Gym {...pageData?.gym} />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDoxNTQy" } }) {
      nodes {
        title
        acfLocation {
          locationHeroDescription
          locationTopgridIntro {
            title
            list {
              value
            }
            icons {
              icon {
                localFile {
                  publicURL
                }
              }
              label
            }
          }
          locationTopgridOne {
            image {
              localFile {
                publicURL
              }
            }
            content {
              title
              description
            }
          }
          locationTopgridTwo {
            left {
              title
              description
            }
            right {
              title
              description
            }
          }
          locationTopgridThree {
            image {
              localFile {
                publicURL
              }
            }
            content {
              title
              description
            }
          }
          locationTopgridFour {
            title
            description
          }
          locationPointsList {
            left {
              label
              value
            }
            right {
              label
              value
            }
          }
          locationPointsImage {
            localFile {
              publicURL
            }
          }
          locationBottomgridOne {
            image {
              localFile {
                publicURL
              }
            }
            content {
              title
              description
            }
          }
          locationBottomgridTwo {
            image {
              localFile {
                publicURL
              }
            }
            content {
              title
              description
            }
          }
          locationBottomgridThree {
            image {
              localFile {
                publicURL
              }
            }
            content {
              title
              description
            }
          }
          locationGymContent {
            title
            description
          }
          locationGymImage {
            localFile {
              publicURL
            }
          }
        }
        seo {
          title
          metaDesc
        }
      }
    }
  }
`

export default Location
