import "./styles.scss"

import React from "react"
import clsx from "clsx"

import { getRawStatus } from "utils/apartments"

const Apartment = ({ name, area, floor, status }) => {
  return (
    <div className="fly-tip-apartment d-flex flex-column flex-grow-1">
      <div>
        {name && (
          <p className="fly-tip-apartment__name d-flex justify-content-center">
            <span>{name}</span>
          </p>
        )}
        {area && (
          <p className="fly-tip-apartment__area">
            Metraż: {area} m<sup>2</sup>
          </p>
        )}
        {floor && <p className="fly-tip-apartment__floor">Piętro: {floor}</p>}
      </div>
      <div
        className={clsx(
          "fly-tip-apartment__status",
          status && `fly-tip-apartment__status--${getRawStatus(status)}`
        )}
      >
        <p>
          {status ? (status === "wolne" ? "dostępne" : status) : "brak statusu"}
        </p>
      </div>
    </div>
  )
}

export default Apartment
