import "./styles.scss"

import React from "react"

import Map from "components/Map"
import Button from "components/Button"

const Location = ({ title, subtitle, description }) => {
  return (
    <section className="investment-location">
      <div className="row g-0">
        <div className="col-xl-9">
          <Map />
        </div>
      </div>

      {(title || subtitle || description) && (
        <div className="investment-location__wrapper">
          <div>
            {title && <h2>{title}</h2>}
            {subtitle && <h4>{subtitle}</h4>}
            {description && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </div>
          <Button to="/lokalizacja/">zobacz</Button>
        </div>
      )}
    </section>
  )
}

export default Location
