import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import {
  TopInfo,
  MarketAdvantages,
  Pricing,
  Grid,
  Tourists,
  DistrictAdvantages,
  Families,
  BottomGrid,
} from "page_components/investor"

import { parseGqlData } from "utils/parseGqlData"

const Investor = ({ data }) => {
  const pageData = parseGqlData({
    data: data?.allWpPage?.nodes,
    queryKey: "Investor",
    single: true,
  })

  return (
    <Layout
      seo={{
        title: pageData?.seo?.title ?? pageData.title,
        description: pageData?.seo?.metaDesc,
      }}
    >
      <PageHeader title={pageData.title} />
      <TopInfo {...pageData?.info} />
      <MarketAdvantages {...pageData?.market} />
      <Pricing {...pageData?.pricing} />
      <Grid {...pageData?.apartments} />
      <Tourists {...pageData?.tourists} />
      <DistrictAdvantages {...pageData?.district} />
      <Families {...pageData?.families} />
      <BottomGrid {...pageData?.sections} />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDoxNDM3" } }) {
      nodes {
        title
        acfInvestor {
          investorInfoContent {
            title
            description
            icons {
              icon {
                localFile {
                  publicURL
                }
              }
              label
            }
          }
          investorInfoBlock {
            number
            postscript
            label
          }
          investorMarketSections {
            image {
              localFile {
                publicURL
              }
            }
            content {
              title
              description
              source
            }
          }
          investorPricingChart {
            image {
              localFile {
                publicURL
              }
            }
            data {
              label
              value
            }
            source
          }
          investorPricingContent {
            title
            description
            vs {
              left {
                value
                postscript
                year
              }
              right {
                value
                postscript
                year
              }
            }
            source
          }
          investorApartmentsContent {
            title
            description
            source
          }
          investorApartmentsImage {
            localFile {
              publicURL
            }
          }
          investorTouristsChart {
            image {
              localFile {
                publicURL
              }
            }
            caption
            source {
              text
              link
            }
          }
          investorTouristsContent {
            title
            description
            source
          }
          investorDistrictContent {
            title
            description
          }
          investorDistrictImage {
            localFile {
              publicURL
            }
          }
          investorFamiliesImage {
            localFile {
              publicURL
            }
          }
          investorFamiliesContent {
            title
            description
          }
          investorSectionsContent {
            content {
              title
              description
            }
            image {
              localFile {
                publicURL
              }
            }
          }
        }
        seo {
          title
          metaDesc
        }
      }
    }
  }
`

export default Investor
