import "./styles.scss"

import React from "react"

import Map from "components/Map"

const DescWithMap = ({ description }) => {
  return (
    <section className="location-desc-with-map">
      <div className="container-fluid">
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
        <Map />
      </div>
    </section>
  )
}

export default DescWithMap
