import "./styles.scss"

import React from "react"

const Building = ({ apartments }) => {
  return (
    <div className="fly-tip-building flex-grow-1">
      <p className="text-uppercase">Dostępne:</p>
      <p className="d-flex justify-content-center"><span>{apartments ?? "0"}</span></p>
    </div>
  )
}

export default Building
