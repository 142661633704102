import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import { Main } from "page_components/privacy_policy"

import { parseGqlData } from "utils/parseGqlData"

const PrivacyPolicy = ({ data }) => {
  const pageData = parseGqlData({
    data: data?.allWpPage?.nodes,
    single: true,
  })

  return (
    <Layout
      seo={{
        title: pageData?.seo?.title ?? pageData.title,
        description: pageData?.seo?.metaDesc,
      }}
    >
      <PageHeader title={pageData.title} />
      <Main content={pageData.content} />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDoxNjIy" } }) {
      nodes {
        title
        content
        seo {
          title
          metaDesc
        }
      }
    }
  }
`

export default PrivacyPolicy
