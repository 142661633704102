import "./styles.scss"

import React from "react"

const History = ({ description, image }) => {
  if (!description) return null

  return (
    <section className="about-history">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Historia</h2>
            <div
              className="about-history__inner"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          {image?.localFile?.publicURL && (
            <div className="col-xl-5 offset-xl-1  col-md-6">
              <img src={image?.localFile?.publicURL} alt="" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default History
