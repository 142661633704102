import "./styles.scss"

import React from "react"

const About = ({ image, content }) => {
  const imageUrl = image?.localFile?.publicURL
  const { title, description } = content ?? {}

  if (!imageUrl && !title && !description) return null

  return (
    <section className="home-about">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            {imageUrl && (
              <div className="home-about__image">
                <img src={imageUrl} alt="" />
              </div>
            )}
          </div>
          {(title || description) && (
            <div
              className="col-xl-5 col-lg-6"
              dangerouslySetInnerHTML={{
                __html: `${title ? `<h2>${title}</h2>` : ""}${
                  description ? description : ""
                }`,
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default About
