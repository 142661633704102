import "./styles.scss"

import React, { useRef } from "react"
import Slider from "react-slick"

const slider = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 750,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  pauseOnHover: false,
}

const Grid = ({ icons, one, two, three, four, five }) => {
  const sliderRef = useRef(null)

  const iconsFiltered =
    icons?.length > 0
      ? icons.filter(item => item?.icon?.localFile?.publicURL && item?.label)
      : undefined

  return (
    <section className="home-grid">
      <div className="container-fluid">
        {iconsFiltered?.length > 0 && (
          <div className="row">
            <div className="col-xxl-6 offset-xxl-6">
              <div className="home-grid__row">
                {iconsFiltered.map((item, index) => (
                  <div key={index} className="home-grid__item">
                    <div className="home-grid__item-image">
                      <img src={item.icon.localFile.publicURL} alt="" />
                    </div>
                    <h4>{item.label}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {one?.content?.title && (
          <>
            <div className="home-grid__divider--100" />

            <div className="row">
              <div className="col-xl-4 col-md-5">
                <h2>{one.content.title}</h2>
                {one?.content?.description && (
                  <div
                    className="home-grid__inner"
                    dangerouslySetInnerHTML={{
                      __html: one.content.description,
                    }}
                  />
                )}
              </div>
              {one?.image?.localFile?.publicURL && (
                <div className="col-xl-7 offset-xl-1 col-md-7">
                  <div className="home-grid__image home-grid__image--1">
                    <img src={one.image.localFile.publicURL} alt="" />
                  </div>
                </div>
              )}
            </div>

            {!one?.image?.localFile?.publicURL && (
              <div className="home-grid__divider--100" />
            )}
          </>
        )}

        {two?.content?.title && (
          <>
            {two?.image?.localFile?.publicURL ? (
              <div className="home-grid__divider--min-50" />
            ) : (
              <div className="home-grid__divider--100" />
            )}

            <div className="row align-items-end flex-column-reverse flex-md-row">
              {two?.image?.localFile?.publicURL && (
                <div className="col-xl-5 col-md-5">
                  <div className="home-grid__image home-grid__image--2">
                    <img src={two.image.localFile.publicURL} alt="" />
                  </div>
                </div>
              )}
              <div className="col-xl-6 col-md-7">
                <h2>{two.content.title}</h2>
                {two?.content?.description && (
                  <div
                    className="home-grid__inner"
                    dangerouslySetInnerHTML={{
                      __html: two.content.description,
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {three?.content?.title && (
          <>
            <div className="home-grid__divider--200" />

            <div className="row">
              <div className="col-md-6">
                <h2>{three.content.title}</h2>
                <div className="row">
                  <div className="col-xl-10">
                    {three?.content?.description && (
                      <div
                        className="home-grid__inner"
                        dangerouslySetInnerHTML={{
                          __html: three.content.description,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              {three?.images?.length > 0 && (
                <div className="col-md-6">
                  <div className="home-grid__image home-grid__image--3">
                    <Slider ref={sliderRef} {...slider}>
                      {three.images.map((item, index) => (
                        <div key={index}>
                          <img src={item?.localFile?.publicURL} alt="" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              )}
            </div>

            {!(three?.images?.length > 0) && (
              <div className="home-grid__divider--100" />
            )}
          </>
        )}

        {four?.content?.title ? (
          <>
            <div className="home-grid__divider--50" />

            <div className="row align-items-center flex-column-reverse flex-md-row">
              <div className="col-xl-5 col-md-6">
                {four?.image?.localFile?.publicURL && (
                  <div className="home-grid__image home-grid__image--4">
                    <img src={four.image.localFile.publicURL} alt="" />
                  </div>
                )}
              </div>
              <div className="col-xl-5 offset-xl-1 col-md-6">
                <h2>{four.content.title}</h2>
                {four?.content?.description && (
                  <div
                    className="home-grid__inner"
                    dangerouslySetInnerHTML={{
                      __html: four.content.description,
                    }}
                  />
                )}
              </div>
            </div>

            {four?.image?.localFile?.publicURL ? (
              <div className="home-grid__divider--min-50" />
            ) : (
              <div className="home-grid__divider--50" />
            )}
          </>
        ) : (
          <div className="home-grid__divider--100" />
        )}

        {five?.content?.title && (
          <>
            <div className="home-grid__divider--100" />

            <div className="row align-items-end">
              <div className="col-md-6">
                <h2>{five.content.title}</h2>
                <div className="row">
                  <div className="col-xl-10">
                    {five?.content?.description && (
                      <div
                        className="home-grid__inner"
                        dangerouslySetInnerHTML={{
                          __html: five.content.description,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {five?.image?.localFile?.publicURL && (
                <div className="col-md-6">
                  <div className="home-grid__image home-grid__image--3">
                    <img src={five.image.localFile.publicURL} alt="" />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default Grid
