import React from "react"

import { useFly } from "context/flytip"

import { getFlyObject, renderSvg } from "../../helpers"

import { data } from "./data"

const BuildingViewFloor0 = ({ apartments }) => {
  const { fly } = useFly()

  return (
    <div className="apartments-building-view__floors-view-group">
      <img
        src={require("assets/images/apartments/Mazowiecka_+0.png").default}
        alt=""
      />
      {renderSvg(data, id => fly(getFlyObject(apartments, id)))}
    </div>
  )
}

export default BuildingViewFloor0
