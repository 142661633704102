import "./styles.scss"

import React from "react"

const About = ({ content, block }) => {
  if (!content?.description && !block?.label?.number && !block?.description)
    return null

  return (
    <section className="investment-about">
      <div className="container-fluid">
        <div className="row justify-content-between">
          {content?.title && (
            <div className="col-xl-3">
              <h2>{content.title}</h2>
            </div>
          )}
          {content?.description && (
            <div className="col-xl-5 col-md-6">
              <div
                className="investment-about__desc"
                dangerouslySetInnerHTML={{ __html: content.description }}
              />
            </div>
          )}
          {(block?.label?.number || block?.description) && (
            <div className="col-xl-4 col-md-6">
              <div className="investment-about__info">
                {block?.label.number && (
                  <h5>
                    {block.label.number}{" "}
                    {block?.label?.postscript && (
                      <span>{block.label.postscript}</span>
                    )}
                  </h5>
                )}
                {block?.description && (
                  <h4 dangerouslySetInnerHTML={{ __html: block.description }} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default About
