import "./styles.scss"

import React from "react"

const Convenience = ({ list }) => {
  if (!(list?.length > 0)) return null

  return (
    <section className="home-convenience">
      <div className="container-fluid">
        <div className="home-convenience__wrapper">
          <div className="row">
            {list.map((item, index) => (
              <div
                key={index}
                className={`col-lg-${
                  index % 4 === 0 ? "4" : [3, 7, 11].includes(index) ? "2" : "3"
                } col-sm-6`}
              >
                <div className="home-convenience__item">
                  <div className="home-convenience__item-image">
                    {item?.icon?.localFile?.publicURL && (
                      <img src={item.icon.localFile.publicURL} alt="" />
                    )}
                  </div>
                  {item?.content?.title && <h4>{item.content.title}</h4>}
                  {item?.content?.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content.description,
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Convenience
