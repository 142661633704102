import "./styles.scss"

import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
} from "react"
import { navigate } from "gatsby"
import {
  useMotionTemplate,
  useMotionValue,
  motion,
  AnimatePresence,
} from "framer-motion"
import clsx from "clsx"

import { CUBIC } from "utils/constants"
import { getRawStatus } from "utils/apartments"
import { getBodyComponent } from "./helpers"

const FlyContext = createContext()

const FlyProvider = ({ children }) => {
  const flyRef = useRef(null)
  const arrowRef = useRef(null)

  const touchDevice = useRef(false)
  const touchDeviceDebounce = useRef(null)

  const [isInit, setIsInit] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const [flyData, setFlyData] = useState(null)

  const handleMouseEnter = object =>
    !touchDevice.current ? setFlyData(object) : undefined

  const handleTouchStart = () => {
    touchDevice.current = true

    setFlyData(null)

    clearTimeout(touchDeviceDebounce.current)

    touchDeviceDebounce.current = setTimeout(() => {
      touchDevice.current = false
    }, 500)
  }

  const handleClick = (object, callback) => {
    const type = object?.type

    if (type === "apartment") {
      const status = object?.status
      const name = object?.name
      const url = object?.url

      if (name && status && getRawStatus(status) !== "sold" && url) {
        navigate(url)
      }
    }

    if (type === "apartment" || type === "building") {
      setIsDisabled(true)
    }

    if (typeof callback === "function") {
      callback()
    }
  }

  const fly = (object, callback) => {
    const status = object?.status
    const className = object?.className

    return {
      className: className ?? clsx(status && getRawStatus(status)),
      style: { cursor: "none" },
      onMouseEnter: () => handleMouseEnter(object),
      onMouseLeave: () => setFlyData(null),
      onTouchStart: handleTouchStart,
      onClick: () => handleClick(object, callback),
    }
  }

  const x = useMotionValue(0)
  const y = useMotionValue(0)

  const fly_transform = useMotionTemplate`translate(${x}px, ${y}px)`

  useEffect(() => {
    const handleMouseMove = e => {
      const fly = flyRef.current
      const arrow = arrowRef.current

      if (fly && flyData?.type) {
        const windowX = e.clientX
        const windowY = e.clientY

        const flyWidth = fly.clientWidth
        const flyHeight = fly.clientHeight

        const arrowHeight = arrow.getBoundingClientRect().height

        x.set(windowX - flyWidth / 2)
        y.set(
          windowY +
            (flyData.type === "building"
              ? (flyHeight + (arrowHeight - 1)) * -1
              : arrowHeight - 1)
        )
      } else if (!isInit) {
        setIsInit(true)
      }
    }

    window.addEventListener("mousemove", handleMouseMove)

    return () => window.removeEventListener("mousemove", handleMouseMove)
  }, [flyData, x, y, isInit])

  useEffect(() => {
    if (isDisabled) {
      setTimeout(() => {
        setIsDisabled(false)
      }, 500)
    }
  }, [isDisabled])

  return (
    <FlyContext.Provider value={{ fly }}>
      {children}
      <AnimatePresence>
        {isInit && !isDisabled && flyData?.type && (
          <motion.div
            ref={flyRef}
            className={`fly-tip fly-tip--${flyData.type} d-flex flex-column text-center`}
            style={{ transform: fly_transform }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: "tween", ease: CUBIC.sharp, duration: 0.2 }}
          >
            {getBodyComponent(flyData)}
            <div ref={arrowRef} className="fly-tip__arrow" />
          </motion.div>
        )}
      </AnimatePresence>
    </FlyContext.Provider>
  )
}

const useFly = () => useContext(FlyContext)

export { useFly }

export default FlyProvider
