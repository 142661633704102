import "./styles.scss"

import React, { useState, useRef, useLayoutEffect } from "react"
import { motion } from "framer-motion"

import { CUBIC } from "utils/constants"

const STEP_MULTIPLIER = 2

const ScrollableView = ({ children }) => {
  const trackRef = useRef(null)

  const initWidth = useRef(null)
  const debounce = useRef(null)

  const [options, setOptions] = useState({
    width: undefined,
    steps: undefined,
    available: false,
  })
  const [step, setStep] = useState(0)
  const [disabled, setDisabled] = useState({
    prev: true,
    next: true,
  })

  const handleClick = (direction = "prev") => {
    setStep(prevStep => {
      const stepsRound = Math.ceil(options.steps)

      if (direction === "next") {
        if (prevStep >= options.steps - 1) {
          return prevStep
        } else {
          const value =
            prevStep + (options.steps - 1) / (stepsRound - 1) / STEP_MULTIPLIER

          setDisabled({
            next: value >= options.steps - 1,
            prev: false,
          })

          return value
        }
      } else {
        if (prevStep <= 0) {
          return prevStep
        } else {
          const value =
            prevStep - (options.steps - 1) / (stepsRound - 1) / STEP_MULTIPLIER

          setDisabled({
            next: false,
            prev: value <= 0,
          })

          return value
        }
      }
    })
  }

  useLayoutEffect(() => {
    const initView = () => {
      const track = trackRef.current
      const windowWidth = document.documentElement.clientWidth

      if (track && windowWidth !== initWidth.current) {
        initWidth.current = windowWidth

        const trackWidth = track.clientWidth
        const ratio = trackWidth / windowWidth
        const width = Math.ceil((ratio * 100).toFixed(2))
        const steps = width / 100
        const available = ratio >= 1

        setStep(0)
        setOptions({
          width,
          steps,
          available,
        })
        setDisabled({
          prev: true,
          next: !available,
        })
      }
    }

    initView()

    const initViewDebounced = () => {
      clearTimeout(debounce.current)

      debounce.current = setTimeout(() => initView(), 100)
    }

    window.addEventListener("resize", initViewDebounced)

    return () => window.removeEventListener("resize", initViewDebounced)
  }, [])

  return (
    <div className="scrollable-view">
      <motion.div
        className="scrollable-view__track"
        ref={trackRef}
        animate={
          options.available
            ? {
                transform: `translateX(${
                  step * ((100 / (options.width / 100)) * -1)
                }%)`,
              }
            : undefined
        }
        transition={{
          type: "tween",
          ease: CUBIC.sharp,
          duration: 0.4,
        }}
      >
        {children}
      </motion.div>
      {options.available && (
        <div className="scrollable-view__navigation d-flex justify-content-center">
          <button
            className="scrollable-view__navigation-arrow"
            type="button"
            disabled={disabled.prev}
            onClick={() => handleClick("prev")}
          >
            ←
          </button>
          <button
            className="scrollable-view__navigation-arrow"
            type="button"
            disabled={disabled.next}
            onClick={() => handleClick("next")}
          >
            →
          </button>
        </div>
      )}
    </div>
  )
}

export default ScrollableView
