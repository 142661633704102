import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import IconBox from "components/IconBox"

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 750,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  pauseOnHover: false,
}

const Content = ({
  images,
  icon,
  title,
  description,
  hasBackground,
  isReversed,
  isSpaced,
  isFull,
}) => {
  const imagesFiltered = images?.filter(image => image?.localFile?.publicURL)

  return (
    <section
      className={`convenients-content${
        hasBackground ? " convenients-content--background" : ""
      }${isReversed ? " convenients-content--reverse" : ""}${
        isSpaced ? " convenients-content--spaced" : ""
      }${isFull ? " convenients-content--full" : ""}`}
    >
      <div className={!isFull ? "container-fluid" : ""}>
        <div className={`row${isReversed ? " flex-lg-row-reverse" : ""}`}>
          {imagesFiltered?.length > 0 && (
            <div className="col-xl-7 col-lg-6">
              {imagesFiltered.length > 1 ? (
                <Slider {...settings}>
                  {imagesFiltered.map((item, index) => (
                    <div key={index}>
                      <div
                        className="convenients-content__image"
                        style={{
                          backgroundImage: `url(${item.localFile.publicURL})`,
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <div
                  className="convenients-content__image"
                  style={{
                    backgroundImage: `url(${imagesFiltered[0].localFile.publicURL})`,
                  }}
                />
              )}
            </div>
          )}
          <div className="col-xl-5 col-lg-6">
            <div className="convenients-content__body">
              <div className="convenients-content__body-inner">
                {icon && <IconBox icon={icon} />}
                {title && <h2>{title}</h2>}
                {description && (
                  <div
                    className="convenients-content__description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
