import "./styles.scss"

import React, { Fragment } from "react"
import PageHeader from "components/PageHeader"

const MarketAdvantages = ({ sections }) => {
  if (!(sections?.length > 0)) return null

  return (
    <section className="investor-market-advantages">
      <div className="container-fluid">
        <PageHeader title="Zalety rynku" type="sub" />

        {sections.map((item, index) => {
          const isOdd = index % 2 === 0

          const title = item?.content?.title
          const description = item?.content?.description
          const imageUrl = item?.image?.localFile?.publicURL
          const source = item?.content?.source

          return (
            <Fragment key={index}>
              {isOdd ? (
                <div className="row">
                  {imageUrl && (
                    <div className="col-xl-5 col-md-5">
                      <img src={imageUrl} alt="" />
                    </div>
                  )}
                  <div className="col-xl-5 offset-xl-1 col-md-7">
                    <div className="investor-market-advantages__wrapper">
                      <div>
                        {title && <h2>{title}</h2>}
                        {description && (
                          <div
                            className="investor-market-advantages__inner"
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        )}
                      </div>
                      {source && <small>Źródło: {source}</small>}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row flex-column-reverse flex-md-row">
                  <div className="col-xl-5 offset-xl-1 col-md-7">
                    <div className="investor-market-advantages__wrapper">
                      <div>
                        {title && <h2>{title}</h2>}
                        {description && (
                          <div
                            className="investor-market-advantages__inner"
                            dangerouslySetInnerHTML={{ __html: description }}
                          />
                        )}
                      </div>
                      {source && <small>Źródło: {source}</small>}
                    </div>
                  </div>
                  {imageUrl && (
                    <div className="col-xl-5 offset-xl-1 col-md-5">
                      <img src={imageUrl} alt="" />
                    </div>
                  )}
                </div>
              )}

              {index + 1 < sections.length && (
                <div className="investor-market-advantages__divider" />
              )}
            </Fragment>
          )
        })}
      </div>
    </section>
  )
}

export default MarketAdvantages
